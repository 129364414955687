import React, { useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { CFade } from '@coreui/react'
import { Loader, PrivateRoute } from './shared'
import { DashBoard, Page404, Page500, Logout } from './pages'
import { MsalProvider } from '@azure/msal-react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import history from './history';
import Login from './pages/Login/Login';
import axios from 'axios';
import Modal from 'react-modal';

interface PropsApp {
  pca?: any
  loggedInUser?: any,
  applicationRetrieve?: any
}
Modal.setAppElement('#root');
const App = ({ pca, loggedInUser = {} }: PropsApp) => {

  useEffect(() => {
    document.title = "SMS Campaign"
  }, [])

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      return error;
    }
  );

  return (
    <>
      <Router history={history}>
        <React.Suspense fallback={<Loader fullScreen />}>
          <MsalProvider instance={pca}>
            <Switch>
              <Route
                exact
                path='/login'
                render={(props) => {
                  if (
                    typeof loggedInUser.ADMIN_API_KEY === 'undefined' ||
                    loggedInUser.ADMIN_API_KEY === null
                  ) {
                    return (
                      <CFade>
                        <Login {...props} />
                      </CFade>
                    )
                  }
                  return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                }}
              />
              <Route exact path='/logout' render={(props) => <Logout {...props} />} />
              <Route exact path='/404' render={(props) => <Page404 {...props} />} />
              <Route exact path='/500' render={(props) => <Page500 {...props} />} />
              <PrivateRoute path='/' name='Home' loggedInUser={loggedInUser}><DashBoard /></PrivateRoute>
            </Switch>
            <ToastContainer autoClose={3500} />
          </MsalProvider>
        </React.Suspense>
      </Router>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
    applicationRetrieve: auth.applicationRetrieve
  }
}

export default connect(mapStateToProps, null)(App)
