import { Redirect, Route } from 'react-router'

const PrivateRoute = ({ children, loggedInUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          typeof loggedInUser.ADMIN_API_KEY === 'undefined' ||
          loggedInUser.ADMIN_API_KEY === null
        ) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }

        return children
      }}
    />
  )
}
export default PrivateRoute
