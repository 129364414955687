import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'

import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './redux/store'
import {
  PublicClientApplication,
  EventType,
  InteractionType,
  AccountInfo,
} from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { login } from './services/authHelper'
import 'smart-inputs/build/styles.css'
import '@coreui/icons/css/free.min.css'
export const msalInstance = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts: AccountInfo[] = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.handleRedirectPromise()
msalInstance.addEventCallback(async (event: any) => {
  switch (event.eventType) {
    case EventType.LOGIN_SUCCESS:
    case EventType.ACQUIRE_TOKEN_SUCCESS:
      if (event.interactionType === InteractionType.Redirect) {
        if (event?.payload?.account && event?.payload?.accessToken) {
          const { account, accessToken } = event.payload
          // FAKE LOGIN
          await login({ accessToken })
          msalInstance.setActiveAccount(account)
        }
      }
      break
    case EventType.LOGOUT_END:
      alert('LOGGED OUT')
  }
})
const ReactTs: any = React
ReactTs['icons'] = icons

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <App pca={msalInstance} />
  </Provider>,
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
