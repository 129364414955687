import { CCol, CRow } from '@coreui/react'
import PeopleIcon from 'src/assets/icons/PeopleIcon'
import ChartBarSimple from 'src/shared/components/charts/ChartBarSimple'
import ChartLineSimple from 'src/shared/components/charts/ChartLineSimple'
import WidgetDropdown from 'src/shared/components/widgets/WidgetDropdown'
import CampaignSearch from '../../Admin/Campaign/Search'

export function CampaignDashboard({
  cardData,
  history
}) {
  return (
    <div style={{ paddingBottom: '80px' }}>
      <CRow style={{ padding: 10 }}>
        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-primary'
            header={cardData.Campaigns}
            text='Campaigns'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-info'
            header={cardData.SmsSent}
            text='SMS Sent'
            footerSlot={
              <ChartLineSimple
                pointed
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[1, 18, 9, 17, 34, 22, 11]}
                pointHoverBackgroundColor='info'
                options={{ elements: { line: { tension: 0.00001 } } }}
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-warning'
            header={cardData.NoCalls}
            text='No. of No Calls'
            footerSlot={
              <ChartLineSimple
                className='mt-3'
                style={{ height: '70px' }}
                backgroundColor='rgba(255,255,255,.2)'
                dataPoints={[78, 81, 80, 45, 34, 12, 40]}
                options={{ elements: { line: { borderWidth: 2.5 } } }}
                pointHoverBackgroundColor='warning'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-danger'
            header={cardData.SmsNotSent}
            text='SMS Pending to be Sent'
            footerSlot={
              <ChartBarSimple
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                backgroundColor='rgb(250, 152, 152)'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm='12' md='12'>
          <CampaignSearch history={history} showHeader={false} />
        </CCol>
      </CRow>
    </div>
  )
}
