// Generated with util/create-search-page.js
import React, { useEffect, useState } from 'react'
import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DeleteModal, InputField, ComboBox } from 'src/shared';
import { ListModel } from 'src/services/modelNew/List/List'
import { JkcDatatable } from "@softsmart/datatable";
import { BASE_API, MODELS, SERVICES, LIST, NO_CALL_MODEL } from 'src/services/routeConst'
import { addAuthAccessTokenToHeaderV2, addAuthAccessTokenToHeader } from 'src/services/authHelper';
import { DATA_TABLE_STYLE, PAGINATION_STYLE, LOADING_TEXT_STYLE } from 'src/constants/Style';
import SmsDetails from "./Detail";
import Modal from 'react-modal';
import moment from 'moment';
import { toast } from 'react-toastify';
const axios = require('axios').default;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '600px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const MyColumns: any = [
  {
    id: "PhoneNo",
    name: "Phone",
    selector: (row: any) => row.PhoneNo,
    width: "14%",
    sortable: true
  },
  {
    id: "Message",
    name: "Message",
    center: false,
    sortable: true,
    cell: (row: any) => <div>{row.Message}</div>
  },
  {
    id: "TimeStamp",
    name: "Time Stamp",
    width: "16%",
    sortable: true,
    selector: (row: any) => row.TimeStamp
  },
  {
    cell: (row: any) => {
      return (
        <button
          className='btn btn-danger btn-sm'
          onClick={() => { row.commandDelete(row) }}
          style={{ backgroundColor: '#ff5b57' }}
        >Delete</button>
      );
    },
    name: "Action",
    width: "100px",
    ignoreRowClick: true,
    allowOverflow: true,
    button: true
  },
];

const SmsSearch = ({ match, modelName = NO_CALL_MODEL }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const [progressModal, setProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [callApi, setCallApi] = useState(false);
  const [count, setCount] = useState(0);

  const [currentId, setCurrentId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [json, setJson] = useState([]);
  const [orderBy, setOrderBy] = useState('TimeStamp');
  const [orderByDir, setOrderByDir] = useState('desc');
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(25);
  const [searchString, setSearchString] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [configId, setConfigId] = useState(null);
  const [theSmsConfig, setTheSmsConfig] = useState<any>([]);
  const [callingDate, setCallingDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const fetchData = async () => {
    setLoading(true);
    let body = {
      Model: 'NoCall',
      Params: [
        {
          "Name": "OrderBy",
          "Value": orderBy
        },
        {
          "Name": "OrderByDir",
          "Value": orderByDir
        },
        {
          "Name": "SearchString",
          "Value": searchString
        },
        {
          "Name": "Page",
          "Value": pageNo
        },
        {
          "Name": "PageSize",
          "Value": pageSize
        },
        {
          "Name": "ConfigId",
          "Value": configId || null
        },
      ]
    }
    await axios.post(`${BASE_API}${SERVICES}${MODELS}${LIST}`, body, { ...addAuthAccessTokenToHeader() }).then((response) => {
      if (response && response.data && response.data.data && response.data.data.ListInfo.length > 0) {
        response.data.data.ListInfo.map((res: any) => {
          res['commandDelete'] = commandDelete;
        })
        setJson(response.data.data.ListInfo);
        setTotalCount(response.data.data.ListInfo[0].RecordCount);
        setLoading(false);
      } else {
        setJson([]);
        setTotalCount(0);
        setLoading(false);
      }
    });
  };

  const commandDelete = async (row: any) => {
    setCurrentId(row.Id);
    setShowDeleteModal(true);
  };

  const commandSearch = (val: any) => { setPageNo(1); setSearchString(val); };
  useEffect(() => { fetchData(); }, [searchString, orderBy, orderByDir, pageNo,configId]);

  const getOlderSms = async (date: any) => {
    setCallApi(false);
    setProgress(progress + 3);
    await axios.post(
      `https://api.softsmartinc.com/Services/v2/Widget/Sms/UpdateBlockList`,
      {
        "ConfigId": configId,
        "DateSent": date
      },
      { ...addAuthAccessTokenToHeaderV2() })

    var a = moment(startDate);
    var b = moment(endDate);
    var diff = b.diff(a, 'days');
    if (count === diff) {
      setProgressModal(false);
      setCallApi(false);
      setCount(0);
      setProgress(0);
      fetchData();
    } else {
      setCount(count + 1);
      setCallApi(true);
    }
  };

  const fetchSmsConfigList = async () => {
    const { data } = await ListModel({
      body: {
        Model: 'SmsConfig',
        Extension: null,
        Params: {},
      },
    })
    let add = data.map((route: any) => {
      return {
        ConfigId: route.Id,
        Description: route.Name,
      }
    })
    setTheSmsConfig(add)
  }

  useEffect(() => {
    if (callApi) {
      let date = moment(callingDate).add(1, 'd').format('YYYY-MM-DD');
      setCallingDate(date);
      getOlderSms(date);
    }
  }, [callApi]);

  useEffect(() => {
    fetchSmsConfigList();
  }, []);

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', justifyContent: 'space-between', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Icon Modal"
        >
          <SmsDetails match={match} closeModal={closeModal} />
        </Modal>

        <Modal
          isOpen={progressModal}
          onRequestClose={() => { setProgressModal(false) }}
          style={customStyles}
          contentLabel="Progress"
        >
          <div className="progress">
            <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
              style={{ width: progress + '%' }} >
            </div>
          </div>
          <div className='mt-2'>Fetching data....</div>
        </Modal>

        <span style={{ color: 'white' }}>{modelName} Search</span>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <div className='mr-2' style={{ background: 'white', display: 'flex',width: '231px' }}>
            <ComboBox
              showButton
              multiple={false}
              name='SmsConfig'
              label='Sms Config'
              placeholder="Select"
              data={theSmsConfig}
              value={(theSmsConfig.find((d: any) => d.ConfigId === configId))}
              handleChange={(e: any, newValue: any) => {
                console.log(e);
                setConfigId(newValue ? newValue.ConfigId : null);
              }}
              classNames={{ input: 'input-field' }}
              customClassNames={{
                button: 'comboBoxInput',
                root: 'input-field-root upscaled config-custom',
              }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
              )}
            />
          </div>
          <div className='mr-2' style={{ background: 'white', display: 'flex' }}>
            <InputField
              name='StartDate'
              type='date'
              label='Start Date'
              value={startDate}
              onChange={(e: any) => {
                let m = moment().subtract(12, 'M');
                var a = moment(endDate);
                var b = moment(e.value);
                var diff = a.diff(b, 'days');
                var diffMonth = b.diff(m, 'days');
                if (diffMonth >= 0) {
                  if (diff >= 0) {
                    setStartDate(e.value);
                    setCallingDate(e.value);
                  } else {
                    toast.error("Please select between date range");
                  }
                } else {
                  toast.error("You can't select more then 180 days previous");
                }
              }}
            />
            <InputField
              name='EndDate'
              type='date'
              label='End Date'
              value={endDate}
              onChange={(e: any) => {
                let m = moment().subtract(12, 'M');
                var a = moment(startDate);
                var b = moment(e.value);
                var diff = b.diff(a, 'days');
                var diffMonth = b.diff(m, 'days');
                if (diffMonth >= 0) {
                  if (diff >= 0) {
                    setEndDate(e.value);
                  } else {
                    toast.error("Please select between date range");
                  }
                } else {
                  toast.error("You can't select more then 180 days previous");
                }
              }}
            />
          </div>
          <CButton
            className='mr-1 ml-auto'
            type='button'
            style={{ height: '35px' }}
            color='primary'
            onClick={() => {
              if (!configId) {
                toast.error("Please select sms config");
              } else {
                setCount(0); getOlderSms(startDate); setProgressModal(true)
              }
            }}
          >
            Refresh
          </CButton>
          <CButton
            className='mr-1 ml-auto'
            type='button'
            style={{ height: '35px' }}
            color='primary'
            onClick={() => openModal()}
          >
            New Sms
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <input type='text' onChange={(event: any) => { commandSearch(event.target.value) }} className='form-control' placeholder='Search...' style={{ width: '25rem', color: 'rgb(0, 0, 0)', boxShadow: 'none' }} />
        <JkcDatatable
          json={json}
          tableColumns={MyColumns}
          customStyles={DATA_TABLE_STYLE}
          paginationStyles={PAGINATION_STYLE}
          loadingTextStyle={LOADING_TEXT_STYLE}
          enableSearch={false}
          loading={loading}
          enablePagination={totalCount > pageSize}
          pageIndex={pageNo}
          pageSize={pageSize}
          totalCount={totalCount}
          onSortChange={(orderBy: any, orderByDir: any) => {
            setPageNo(1);
            setOrderBy(orderBy);
            setOrderByDir(orderByDir);
          }}
          onPageChange={setPageNo}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Sms', id: currentId }}
        callBack={() => fetchData()}
      />
    </>
  )
}

export default SmsSearch
