import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { PhoneHeader, PhoneInfo } from './components'
import { PHONE_MODEL } from 'src/services/routeConst'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const fields = ['Id', 'PhoneNo', 'TimeStamp']

const PhoneDetails = ({ history, match, modelName = PHONE_MODEL }) => {
  const [apiData, setApiData] = useState<any>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')
  const changesRef = useRef<any>()

  const { id = 0 } = match.params

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(
    async (shouldUpdateRefs = true) => {
      try {
        // busco dato y no me lo devuelve me devuelve el  defaultMainData
        const { mainData } = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ modelData: dataResponse })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository, id, modelName],
  )

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
  }
  useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setApiData({ menuData: null })
        history.goBack()
        resolve(true)
      }, delay)
    })
  }

  const saveData = async () => {
    try {
      const dataToSave: any = apiData.modelData

      setStatus('saving')
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field]}))),
        },
      })
      toast.success(message)
      updateRefs()
      setStatus('idle')
      await goBack(250)
    } catch (error) {
      setStatus('idle')
      toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData } = apiData
  return (
    <CCard style={{border: 'none'}}>
      <PhoneHeader status={status} saveData={saveData} onCancel={history.goBack} />
      <CCardBody>
        <DetailTabSection
            id={id}
            hideNotes={true}
            modelName={modelName}
            tabs={[
              { name: 'Details', label: 'Phone Info' }
            ]}
          >
          <CTabPane data-tab='Details' className='detail-tab-section-pane'>
            <CRow>
            <CCol xs='12' md='6'>
              <PhoneInfo modelData={modelData} handleChange={handleChange} />
            </CCol>

            {/* {id ? (
              <CCol xs='12' md='6'>
                <DetailTabSection
                  id={id}
                  Guid={modelData.Guid}
                  modelName={modelName}
                  changesRef={changesRef}
                />
              </CCol>
            ) : null} */}
          </CRow>
          </CTabPane>
        </DetailTabSection>
        
      </CCardBody>
    </CCard>
  )
}

export default PhoneDetails
