import React from 'react'
import { CFooter } from '@coreui/react'
import { useContext } from 'react'
import { DashboardContext } from './DashBoard'
import CIcon from '@coreui/icons-react'
interface PropsFooter {
  show?: boolean
}
const TheFooter = ({ show = false }: PropsFooter) => {
  const { upscaled, toggleUpscaled } = useContext(DashboardContext)

  return (
    <CFooter
      fixed={true}
      style={{
        justifyContent: 'space-between',
        userSelect: 'none',
        backgroundColor: '#2f3c54',
        borderTop: '1.5px solid red',
        position: 'fixed',
        zIndex: 1,
      }}
    >
      {show && (
        <>
          <div>
            <a href='/' target='_blank' rel='noopener noreferrer' style={{ color: 'white' }}>
              Admin Panel
            </a>
            <span className='ml-1' style={{color: '#fff'}}>&#169; 2023 v1.0.00</span>
          </div>
          <div>
            <CIcon
              name={`cil-zoom-${upscaled ? 'out' : 'in'}`}
              style={{ cursor: 'pointer' }}
              className='mfe-2 upscale-icon'
              onClick={toggleUpscaled}
            />
          </div>
        </>
      )}
    </CFooter>
  )
}
export default React.memo(TheFooter)
