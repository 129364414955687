import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { constructSidebarItems } from 'src/services/menu/menuService'
import { MenusWidgetsList } from 'src/services/modelNew/List/List'
import { BASE_API, SERVICES } from 'src/services/routeConst'
import routes from '../routes'

const getSubRoutesFor = ({ name, Component, path, subRoute = 'Detail' }: any) => {
  require(`../pages${path?.replace('/Search', `/${subRoute}`)}`)
  const DetailComponent = React.lazy(() =>
    import(`../pages${path?.replace('/Search', `/${subRoute}`)}`).catch((err) =>
      console.error(err),
    ),
  )
  return [
    { name, Component, path },
    {
      name: name + ' Detail',
      Component: DetailComponent,
      path: path.replace('/Search', `/${subRoute}/:id`),
    },
    {
      name: 'New ' + name,
      Component: DetailComponent,
      path: path.replace('/Search', '/New'),
    },
  ]
}

const parseRoutes = (routes: any) => {
  return [].concat(
    ...routes
      .map((route: any) => {
        const { path, name, component } = route
        const Component =
          path &&
          React.lazy(() =>
            import(`../pages${component || path}`).catch((err) => console.error(err)),
          )

        if (path && (component || path)?.includes('/Search')) {
          try {
            return getSubRoutesFor({ name, Component, path: component || path, subRoute: 'Detail' })
          } catch (err: any) {
            try {
              return getSubRoutesFor({
                name,
                Component,
                path: component || path,
                subRoute: 'Edit',
              })
            } catch (error: any) { }
          }
        }
        try {
          require(`../pages${component || path}`)
          return { name, Component, path }
        } catch (err: any) {
          return null
        }
      })
      .filter(Boolean),
  )
}

export const useGetMenuRoute = () => {
  const [navData, setNavData] = React.useState<any>({ routes: [], nav: [] })
  const getMenuRoutes = React.useCallback(async () => {
    try {
      const menuRoutes = await getRoutes([])
      let navItems = await constructSidebarItems(menuRoutes)
      await setNavData({
        routes: parseRoutes([...routes, ...menuRoutes]), nav: navItems
      })
    } catch (error: any) {
      toast.error((error as any)?.message || error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    getMenuRoutes()
  }, [getMenuRoutes])

  const getRoutes = (data: any) =>
    data.map(({ Id, IsMain, MainMenuName, Name, Description, Icon, Color, PagePath = 'Leads/Details/1' }: any) => ({
      Description,
      Icon,
      Color,
      IsMain: IsMain,
      MainMenuName,
      sub_menus: IsMain ? data.filter(({ MENUS_ID_Parent }: any) => MENUS_ID_Parent === Id) : [],
      name: Name,
      path: PagePath,
    }))

  return [navData, getMenuRoutes]
}
