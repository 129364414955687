import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const SmsTableColumns: ColumnData[] = [
  {
    label: 'Phone',
    name: 'PhoneNo',
    customWidth: 18,
  },
  {
    label: 'Message',
    name: 'Message',
    customWidth: 55,
  },
  {
    label: 'Time Stamp',
    name: 'TimeStamp',
    customWidth: 20,
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['delete'],
     customWidth: 4,
    sortable: false,
  },
]
