import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { CampaignDashboard } from './components/CampaignDashboard';
import axios from 'axios'

const Dashboard: React.FC<any> = () => {
  const history = useHistory();
  

  const [cardData, setCardData] = useState({ Dispatches: 0, Inspectors: 0, Revenue: 0, Inspections: 0 })
  
  const fetchCardData = async () => {
    try {
      let params = [];
      let procedureName = "access.APPLICATIONS_DashboardSms";
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        {
          "DbName": "DbAdmin",
          "QueryOrProcedureName": procedureName,
          "Params": params
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setCardData(response.data.data.Execution[0])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCardData();
  }, []);

  return (
    <CampaignDashboard cardData={cardData} history={history} />
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    applicationRetrieve: auth.applicationRetrieve,
    loggedInUser: auth.loggedInUser
  }
}

export default React.memo(connect(mapStateToProps, null)(Dashboard))
