import React, { useState, useRef, useMemo, useEffect } from 'react'
import classNames from 'classnames'

export const Context = React.createContext<any>({})
interface PropsSideBar {
  children?: any
  className?: any
  innerRef?: any
  fixed?: any
  unfoldable?: any
  overlaid?: any
  breakpoint?: any
  minimize?: any
  show?: any
  size?: any
  hideOnMobileClick?: any
  aside?: any
  colorScheme?: any
  dropdownMode?: any
  onShowChange?: any
  onMinimizeChange?: any
}
//component - CoreUI / CSidebar
const CSidebar: React.FC<any> = ({
  fixed = true,
  breakpoint = 'lg',
  show = 'responsive',
  hideOnMobileClick = true,
  colorScheme = 'dark',
  ...props
}: PropsSideBar) => {
  /* fixed: true,
  breakpoint: 'lg',
  show: 'responsive',
  hideOnMobileClick: true,
  colorScheme: 'dark', */
  const {
    children,
    className,
    innerRef,
    unfoldable,
    minimize,
    size,
    dropdownMode,
    aside,
    overlaid,
    onShowChange,
    onMinimizeChange,
    ...attributes
  } = props
  const key = useState(Math.random().toString(36).substr(2))[0]

  const [isOpen, setIsOpen] = useState(show)
  const [openDropdown, setOpenDropdown] = useState<any>()

  const node = useRef<any>({}).current
  const reference = (r: any) => {
    node.current = r
    innerRef && innerRef(r)
  }

  const [minimized, setIsMinimized] = useState(minimize)
  useMemo(() => {
    setIsMinimized(minimize)
  }, [minimize])

  const toggleMinimize = () => {
    setIsMinimized(!minimized)
    onMinimizeChange && onMinimizeChange(minimized)
  }

  useMemo(() => {
    setIsOpen(show)
  }, [show])

  useEffect(() => {
    isOpen === true ? createBackdrop() : removeBackdrop()
    return () => removeBackdrop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  //methods
  const sidebarCloseListener = (e: any) => {
    if (document.getElementById(key + 'backdrop') && !node.current.contains(e.target)) {
      closeSidebar()
    }
  }

  const onKeydown = (e: any) => {
    e.key.includes('Esc') && isAutoclosable() && closeSidebar()
  }

  const createBackdrop = () => {
    const backdrop = document.createElement('div')
    if (overlaid) {
      document.addEventListener('click', sidebarCloseListener)
    } else {
      backdrop.addEventListener('click', closeSidebar)
    }
    document.addEventListener('keydown', onKeydown)
    backdrop.className = 'c-sidebar-backdrop c-show'
    backdrop.id = key + 'backdrop'
    document.body.appendChild(backdrop)
  }

  const removeBackdrop = () => {
    const backdrop = document.getElementById(key + 'backdrop')
    if (backdrop) {
      document.removeEventListener('click', sidebarCloseListener)
      backdrop.removeEventListener('click', closeSidebar)
      document.removeEventListener('keydown', onKeydown)
      document.body.removeChild(backdrop)
    }
  }

  const closeSidebar = () => {
    if (typeof onShowChange === 'function') {
      onShowChange(overlaid ? false : 'responsive')
    } else {
      setIsOpen(overlaid ? false : 'responsive')
    }
  }

  const isOnMobile = () => {
    return Boolean(getComputedStyle(node.current).getPropertyValue('--is-mobile'))
  }

  const isAutoclosable = () => isOnMobile() || overlaid

  const onSidebarClick = (e: any) => {
    const sidebarItemClicked = String(e.target.className).includes('c-sidebar-nav-link')
    if (sidebarItemClicked && hideOnMobileClick && isAutoclosable()) {
      closeSidebar()
    }
  }

  // render
  const haveResponsiveClass = breakpoint && isOpen === 'responsive'
  const classes = classNames(
    'c-sidebar',
    colorScheme && `c-sidebar-${colorScheme}`,
    isOpen === true && 'c-sidebar-show',
    haveResponsiveClass && `c-sidebar-${breakpoint}-show`,
    fixed && !overlaid && 'c-sidebar-fixed',
    aside && 'c-sidebar-right',
    minimized && !unfoldable && 'c-sidebar-minimized',
    minimized && unfoldable && 'c-sidebar-unfoldable',
    overlaid && 'c-sidebar-overlaid',
    size && `c-sidebar-${size}`,
    className,
  )

  return (
    <Context.Provider
      value={{
        dropdownMode,
        scrollbarExist: !minimized || unfoldable,
        toggleMinimize,
        openDropdown,
        setOpenDropdown,
      }}
    >
      <div className={classes} {...attributes} ref={reference} onClick={onSidebarClick}>
        {children}
      </div>
    </Context.Provider>
  )
}

export default CSidebar
