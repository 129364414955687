import React, { useContext, useEffect, useRef } from 'react'
import {
  // CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
// import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { setUser } from '../../../redux/actions/authActions'
import EditProfileModal from './headerDropdownModals/EditProfileModal'
import ChangePasswordModal from './headerDropdownModals/ChangePasswordModal'
import ChangeProfilePictureModal from './headerDropdownModals/ChangeProfilePictureModal'
// import { routeToPage } from 'src/services/routingHelper'
import { addAuthToHeader } from 'src/services/authHelper'
import { DownloadFile } from 'src/services/modelNew/Widget/File/Download'
import { BASE_API, LOGIN, USERS, SERVICES, RETRIEVE } from 'src/services/routeConst'
import axios from 'axios'
import { DashboardContext } from 'src/containers/DashBoard'
const TheHeaderDropdown: React.FC<any> = (props) => {
  const { updateAvatarProfile } = useContext(DashboardContext)
  const [modalToShow, setModalToShow] = React.useState<any>()
  const { loggedInUser = {} } = props
  const { UsersId, Id, FirstName, LastName } = loggedInUser.Auth.User
  const [FilesId_Avatar, setFilesIdAvatar] = React.useState<any>()
  // const history = useHistory()
  const zoomModeEditor = useRef<HTMLDivElement | any>()

  useEffect(() => {
    axios
      .post(
        `${BASE_API}${SERVICES}/${LOGIN}/${USERS}/${RETRIEVE}`,
        {
          Id: Id,
        },
        { ...addAuthToHeader('ADMIN') },
      )
      .then((response) => {
        const fetchFiles = async () => {
          try {
            const { data } = await DownloadFile({ guid: response.data.data.AvatarFile.Guid })
            data && setFilesIdAvatar(data)
          } catch (error: any) {
            
          }
        }
        fetchFiles()
      })
  }, [Id, updateAvatarProfile])

  // try {
  //   const { data } = await DownloadFile({ guid: guid })
  //   data && setCropData(data)
  //   data && setImage(data)
  // } catch (error: any) {}

  const logoutClearData = () => {
    localStorage.clear()
    window.location.reload()
  }
  const CDropdownTS: any = CDropdown
  return (
    <>
      <div ref={zoomModeEditor}>
        <CDropdownTS inNav className='c-header-nav-items mx-2' direction='down'>
          <CDropdownToggle
            className='c-header-nav-link'
            caret={false}
            style={{ marginRight: '0.75rem' }}
          >
            <p className='mr-2 mb-0'>
              Welcome {FirstName} {LastName}
            </p>
            <div className='c-avatar ml-3'>
              <CImg
                src={FilesId_Avatar}
                style={{ width: '3rem' }}
                alt='admin@bootstrapmaster.com'
              />
            </div>
          </CDropdownToggle>
          <CDropdownMenu className='pt-0' placement='bottom-end'>
            {/* <CDropdownItem header tag='div' color='light' className='text-center'>
              <strong>Account</strong>
            </CDropdownItem>
            <CDropdownItem onClick={() => setModalToShow('editProfile')}>
              <CIcon name='cil-user' className='mfe-2' />
              Edit Profile
            </CDropdownItem> */}
            {/* <CDropdownItem onClick={() => setModalToShow('changePassword')}>
              <CIcon name='cil-user' className='mfe-2' />
              Change Password
            </CDropdownItem> */}
            {/* <CDropdownItem onClick={() => setModalToShow('changeProfilePicture')}>
              <CIcon name='cil-user' className='mfe-2' />
              Change Profile Picture
            </CDropdownItem>
            <CDropdownItem header tag='div' color='light' className='text-center'>
              <strong>Settings</strong>
            </CDropdownItem>
            <CDropdownItem onClick={() => routeToPage(history, `/Profile`)}>
              <CIcon name='cil-user' className='mfe-2' />
              Profile
            </CDropdownItem> */}
            {/* {zoomMode === Zoom.ENABLED ? (
              <CDropdownItem onClick={() => changeZoomMode(Zoom.DISABLED)}>
                <CIcon name='cil-zoom-out' className='mfe-2' />
                Zoom mode off
              </CDropdownItem>
            ) : (
              <CDropdownItem onClick={() => changeZoomMode(Zoom.ENABLED)}>
                <CIcon name='cil-zoom-in' className='mfe-2' />
                Zoom mode on
              </CDropdownItem>
            )} */}

            {/* <CDropdownItem>
              <CIcon name='cil-settings' className='mfe-2' />
              Settings
            </CDropdownItem> */}
            {/* <CDropdownItem>
          <CIcon name='cil-credit-card' className='mfe-2' />
          Payments
          <CBadge color='secondary' className='mfs-auto'>
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name='cil-file' className='mfe-2' />
          Projects
          <CBadge color='primary' className='mfs-auto'>
            42
          </CBadge>
        </CDropdownItem> */}
            {/* <CDropdownItem divider style={{ marginTop: 0 }} /> */}
            {/* <CDropdownItem>
          <CIcon name='cil-lock-locked' className='mfe-2' />
          Lock Account
        </CDropdownItem> */}
            <CDropdownItem
              onClick={() => {
                logoutClearData()

                // handleLogout();
                // props.setUser(null)
                // history.push('/login')
              }}
            >
              <CIcon name='cil-account-logout' className='mfe-2' />
              Sign Out
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdownTS>

        <EditProfileModal
          showModal={modalToShow === 'editProfile'}
          UsersId={UsersId}
          setShowModal={setModalToShow}
        />
        <ChangePasswordModal
          showModal={modalToShow === 'changePassword'}
          userData={loggedInUser}
          setShowModal={setModalToShow}
        />
        <ChangeProfilePictureModal
          showModal={modalToShow === 'changeProfilePicture'}
          userData={loggedInUser}
          setShowModal={setModalToShow}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (data: any) => dispatch(setUser(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdown))
