import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import { Loader } from 'src/shared'
import { Page404 } from 'src/pages'
import 'library-soft-datatable/build/styles.css';

const TheContent = ({ routes }: any) => {
  return (
    <main>
      <CContainer
        fluid
        style={{ margin: 0, padding: 0, backgroundColor: 'white', minHeight: '90vh' }}
      >
        <Suspense fallback={<Loader fullScreen height='75vh' />}>
          <Switch>
            {routes.map((route: any, idx: number) => {
              const { path, Component }: any = route
              return (
                Component && (
                  <Route
                    key={idx}
                    exact
                    path={path}
                    render={(props) => (
                      <CFade>
                        <Component {...props} />
                      </CFade>
                    )}
                  />
                )
              )
            })}
            <Route
              path='*'
              render={(props) => (
                <CFade>
                  <Page404 {...props} />
                </CFade>
              )}
            />
            <Redirect from='/' to='/dashboard' />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
