export const DATA_TABLE_STYLE = {
  rows: {
    style: {
      minHeight: "38px",
      borderBottom: "0px !important",
    },
  },
  headRow: {
    style: {
      minHeight: "38px",
      backgroundColor: "#3c4b64",
      borderTop: "1px solid #EDEDED !important",
      borderBottom: "none !important",
      borderRight: "none !important"
    },
    denseStyle: {
      minHeight: "26px",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      border: "1px solid #EDEDED !important",
      borderTop: "none !important",
      borderRight: "none !important",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "6px",
      paddingBottom: "6px",
      fontWeight: "600",
      fontFamily: "Josefin-bold",
      color: '#ffffff'
    },
  },
  cells: {
    style: {
      minHeight: "32px",
      fontSize: "14px",
      border: "1px solid #EDEDED !important",
      borderTop: "none !important",
      borderRight: "none !important",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "8px",
      paddingBottom: "8px",
      fontFamily: "Josefin-Regular",
      color: "#212529"
    },
    denseStyle: {
      minHeight: "32px",
    },
  }
};
export const PAGINATION_STYLE = {
  pagination: {
    marginTop: "5px",
    justifyContent: "center",
    display: "flex",
    flexWrap: "Wrap"
  },
  button: {
    borderRadius:'8px',
    height: "32px",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #ccc",
    backgroundColor: "white",
    marginBottom: '3px',
    fontFamily: "Josefin-Regular"
  },
  active: {
    backgroundColor: "#3c4b64",
    border: "1px solid #3c4b64",
    color: "white"
  },
  inactive: {
    color: "#191970",
    backgroundColor: "#FFFFFF",
  },
  hoverColor: "#3c4b64",
};
export const LOADING_TEXT_STYLE = {
  fontFamily: "Josefin-Regular"
};
export const ACTION_BUTTON_STYLE = {
  border: "none",
  padding: "4px 10px",
  fontSize: "11pt",
  borderRadius: "5px",
  color: "white",
  textDecoration: "none",
  fontFamily: "Arial",
  display:'flex',
  alignItems: 'center'
};