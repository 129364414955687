import React from 'react'
import { connect } from 'react-redux'
import {
  CCreateElement,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle
} from '@coreui/react'
import { setSidebarShow } from '../redux/actions/SidebarActions'
import { CSidebarCustom, CSidebarNavDropdownCustom, CSidebarNavItemCustom } from 'src/shared'
import TheFooter from './TheFooter'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'

const TheSidebar: React.FC<any> = (props: any) => {
  const { applicationRetrieve = {} } = props
  const { LogoGuid } = applicationRetrieve
  const components: any = {
    CSidebarNavDivider,
    CSidebarNavDropdown: CSidebarNavDropdownCustom,
    CSidebarNavItem: CSidebarNavItemCustom,
    CSidebarNavTitle,
  }

  return (
    <CSidebarCustom
      show={props.show}
      onShowChange={(val: any) => {
        props.setSidebarShow(val)
      }}
    >
      <CSidebarBrand className='d-md-down-none' style={{fontSize: '22px'}} to='/'>
        {/* <img
          src={`${BASE_API}${SERVICES}${WIDGETS}/Files/Download/${LogoGuid || ''}`}
          style={{width: '150px',maxWidth:'180px' }}
          alt=''
        /> */}
        SMS Campaign
      </CSidebarBrand>
      <CSidebarNav style={{ borderTop: '1px solid red' }}>
        <CCreateElement items={[
          {
            _children : ['Navigation'],
            _tag: "CSidebarNavTitle"
          },
          {
            icon: "cil-group",
            name: "Campaign",
            to: "/Admin/Campaign/Search",
            _tag: "CSidebarNavItem"
          },
          {
            icon: "cil-mobile",
            name: "NoCall",
            to: "/Admin/Sms/Search",
            _tag: "CSidebarNavItem"
          }
        ]} components={components} />
      </CSidebarNav>
      <TheFooter show />
    </CSidebarCustom>
  )
}

const mapStateToProps = (state: any) => {
  const { sidebar, auth } = state
  return {
    show: sidebar.sidebarShow,
    applicationRetrieve : auth.applicationRetrieve
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSidebarShow: (data: any) => dispatch(setSidebarShow(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheSidebar))
