// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField, ComboBox } from 'src/shared'
import { ListModel } from 'src/services/modelNew/List/List'
import { useState, useEffect, useCallback } from 'react'
//import DateTimePicker from 'react-datetime-picker';
//import Form from 'react-bootstrap/Form';

const CampaignInfo = ({ modelData, handleChange /**, handleCheckBoxChange */ }) => {
  const [theSmsConfig, setTheSmsConfig] = useState<any>({ CodeId: 0, Description: '' })
  const [selectedSmsConfig, setSelectedSmsConfig] = useState<any>([])
  const {
    Name,
    Message,
    ConfigId /** , Description, MessageWithFields, FieldList*/ /**,SendOn, IsSent  */,
  } = modelData

  const fetchSmsConfigList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: 'SmsConfig',
        Extension: null,
        Params: {},
      },
    })
    let add = data.map((route) => {
      if (route.Id == ConfigId) {
        setSelectedSmsConfig({
          ConfigId: route.Id,
          Description: route.Name,
        })
      }
      return {
        ConfigId: route.Id,
        Description: route.Name,
      }
    })
    setTheSmsConfig(add)
  }, [])

  const handleComboChangeCheck = (e: any, newValue: any = {}) => {
    if (!e.target) return
    setSelectedSmsConfig(newValue)
    // var commaSeparated = newValue
    //   .map((item) => {
    //     return item.CodeId
    //   })
    //   .join(',')
    handleChange({ name: 'ConfigId', value: newValue.ConfigId })
  }

  useEffect(() => {
     fetchSmsConfigList()
    // if (modelData.Id != 0) {
    //   const splitUsers = UsersList.split(',')
    //   const filterUsersArray = async () => {
    //     const myArrayFiltered = await usersData.filter((el) => {
    //       return splitUsers.some((f) => {
    //         return el.Id == f
    //       })
    //     })

    //     const myF = myArrayFiltered.map((route) => {
    //       return {
    //         CodeId: route.Id,
    //         Description: route.FirstName,
    //       }
    //     })
    //     setSelectedUsers(myF)
    //   }
    //   filterUsersArray()
    // }
  }, [])
  return (
    <>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <ComboBox
            showButton
            multiple={false}
            name='SmsConfig'
            label='Sms Config'
            data={theSmsConfig}
            //value={usersSelectedData ?? []}
            value={selectedSmsConfig ?? []}
            handleChange={handleComboChangeCheck}
            classNames={{ input: 'input-field' }}
            customClassNames={{
              button: 'comboBoxInput',
              root: 'input-field-root upscaled custom-multi',
            }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </CCol>

        {/* <CCol xs='4' sm='4'>
          <InputField
            name='SendOn'
            type='mask'
            mask={[/[0-1]/, /\d/, '-', /[0-3]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', /[0-2]/, /\d/,':',/[0-5]/, /\d/,':',/[0-5]/, /\d/,' ',/['A,P']/,/['M']/]}
            label='Send On'
            value={SendOn ?? ''}
            onChange={handleChange}
          />

        </CCol> */}

        {/* <CCol xs='4' sm='4'> */}
        {/* <CSwitch
                    color="success"
                    checked
                    value={IsSent ?? false}
                    size='sm'
                    onChange={handleChange}
                    variant="outline"
                    shape='pill'
                  />
                   */}

        {/* <Form.Check 
        type="switch"
        id="custom-switch"
        label="Is Sent"
        checked={IsSent ?? false}
        name='IsSent'
        onChange={handleCheckBoxChange}
        disabled={true}
      /> */}
        {/* <InputField
            name='IsSent'
            type='text'
            label='Is Sent'
            value={IsSent ?? ''}
            onChange={handleChange}
          /> */}
        {/* </CCol> */}
      </CFormGroup>
      <CFormGroup row>
        {/* <CCol xs='12' sm={modelData.Id === 0 ? '6': '12'}>
          <InputField
            name='Description'
            type='textarea'
            label='Description'
            value={Description ?? ''}
            onChange={handleChange}
            textAreaProps={{ rows: 5 }}
          />
        </CCol> */}
        <CCol xs='12' sm={modelData.Id === 0 ? '12' : '12'}>
          <InputField
            name='Message'
            type='textarea'
            label='Message'
            value={Message ?? ''}
            onChange={handleChange}
            textAreaProps={{ rows: 5 }}
          />
        </CCol>
        {/* <CCol xs='12' sm={modelData.Id === 0 ? '6' : '12'}>
          <InputField
            name='FieldList'
            type='textarea'
            label='Field List'
            disabled={true}
            value={FieldList ?? ''}
            onChange={handleChange}
            textAreaProps={{ rows: 5 }}
          />
        </CCol> */}
      </CFormGroup>
    </>
  )
}

export default CampaignInfo
