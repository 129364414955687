import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React from 'react'
import { FieldsSection } from 'src/shared'

const ChangeProfilePictureModal = ({ showModal, setShowModal }: any) => {
  const [userData, setUserData] = React.useState({})

  const saveData = async () => {
    setShowModal(false)
    setUserData({})
  }

  // const handleChange = ({ name = '', value = '' }) => {
  //   name && setUserData((st: any) => ({ ...st, [name]: value }))
  // }
  return (
    <CModal
      show={showModal}
      onClose={() => {
        setUserData({})
        setShowModal(false)
      }}
      color='info'
    >
      <CModalHeader closeButton>
        <CModalTitle>Change Profile Picture</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Profile Picture'>
              <CFormGroup row className='justify-content-center'>
                {/*  src={userData.profile_picture}  */}
                {/** cropShape={'rect' | 'round'} */}
                {/* <Cropper
                  cropShape={'round'}
                  maxSizeMib={3}
                  guid={54}
                  onChangeCropper={(e: any) => {
                    
                  }}
                /> */}
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color='info' onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            setUserData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default ChangeProfilePictureModal
